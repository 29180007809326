import { GET_PORTABILITIES, GET_PORTABILITIES_HISTORY } from "../actions/VendorsAction"


const initialState = {
    portabilities: [],
    portabilityHistory: [],

}

const VendorReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_PORTABILITIES: 
            return {
                ...state,
                portabilities: [...action.payload]
            }
        case GET_PORTABILITIES_HISTORY: 
            return {
                ...state,
                portabilityHistory: [...action.payload]
            }
        default: 
            return {
                ...state
            }
        
    }
}

export default VendorReducer

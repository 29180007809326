import axios from "./../../../axios"

export const GET_STATUS_LIST = 'GET_STATUS_LIST'
export const GET_ORDER_LIST = 'GET_ORDER_LIST'

export const getOrderStatus = () => (dispatch) => {
    axios.get('order-status/all').then((res) => {
        dispatch({
            type: GET_STATUS_LIST,
            payload: res.data.result,
        })
    })
}
export const getOrderStatusById = (id) => () => {
    return axios.get('order-status/find/' + id).then((res) => {
        return res.data.result
    })
}
export const createOrderStatus = (data) => () => {
    return axios.post('order-status/create', data).then((res) => {
        return res.data
    })
}
export const updateOrderStatus = (data) => () => {
    return axios.post('order-status/update', data).then((res) => {
        return res.data
    })
}
export const deleteOrderStatus = (id) => () => {
    return axios.delete('order-status/delete/' + id).then((res) => {
        return res.data
    })
}
export const getOrders = () => (dispatch) => {
    axios.get('carrier/all').then((res) => {
        dispatch({
            type: GET_ORDER_LIST,
            payload: res.data.result,
        })
    })
}

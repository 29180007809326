import {
    GET_ROLE
} from '../actions/RoleAction'

const initialState = []

const RoleReducer = function (state = initialState, action) {

    switch (action.type) {
        case GET_ROLE: {
            return [...action.payload]
        }

        default: {
            return [...state]
        }
    }
}

export default RoleReducer

import axios from "./../../../axios"

export const GET_TOOLTIPS = 'GET_TOOLTIPS'
export const CREATE_TOOLTIPS = 'CREATE_TOOLTIPS'
export const DELETE_TOOLTIPS = 'DELETE_TOOLTIPS'
export const DELETE_ALL_TOOLTIPS = 'DELETE_ALL_TOOLTIPS'
export const GET_FORMLIST = 'GET_FORMLIST'
export const GET_STEPLIST = 'GET_STEPLIST'


export const getTooltips = () => (dispatch) => {
    axios.get('tooltips/all').then((res) => {
        dispatch({
            type: GET_TOOLTIPS,
            payload: res.data.result,
        })
    })
}

export const createTooltips = (dispatch) => async () => {
    return axios.post('tooltips/create', dispatch).then((res) => {
        return res.data;
    })
}

export const getFormList = () => (dispatch) => {
    return axios.get('tooltips/formlist').then((res) => {
        dispatch({
            type: GET_FORMLIST,
            payload: res.data.result,
        })
        return res.data;
    })
}

export const getStepList = () => (dispatch) => {
    return axios.get('tooltips/steplist').then((res) => {
        dispatch({
            type: GET_STEPLIST,
            payload: res.data.result,
        })
        return res.data;
    })
}


// export const changeStatus = (data, id) => async () => {
//     return axios.patch('carrier/change-status/' + id, data).then((res) => {
//         return res.data;
//     })
// }


export const updateTooltips = (dispatch) => async () => {
    return axios.post('tooltips/update', dispatch).then((res) => {
        return res.data;
    })
}

export const getTooltipsById = (id) => (dispatch) => {
    return axios.get('tooltips/show/' + id).then((res) => {
        return res.data;
    })
}

export const deleteTooltips = (id) => async () => {
    return axios.delete('tooltips/delete/' + id).then((res) => {
        return res.data;
    })
}

// export const massDelete = (dispatch) => async () => {
//     return axios.post('tooltips/mass-delete', dispatch).then((res) => {
//         return res.data;
//     })
// }

// export const massApproved = (data) => (dispatch) => {
//     return axios.post('tooltips/mass-approved', data).then((res) => {
//         // dispatch({
//         //     type: MARK_AS_READ,
//         //     payload: res.data.result,
//         // })
//         return res.data.result;
//     })
// }

// export const sortData = (data) => (dispatch) => {
//     return axios.get('tooltips/disapprove', data).then((res) => {
//         dispatch({
//             type: GET_TOOLTIPS,
//             payload: res.data.result,
//         })
//     })
// }


import axios from "./../../../axios"

export const GET_CUSTOMER = 'GET_CUSTOMER'
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER'
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'
export const DELETE_ALL_CUSTOMER = 'DELETE_ALL_CUSTOMER'

export const getCustomers = () => (dispatch) => {
    axios.get('customers/all').then((res) => {
        dispatch({
            type: GET_CUSTOMER,
            payload: res.data.result,
        })
    })
}

export const deleteCustomer = (id) => (dispatch) => {
    return axios.post('customers/delete', { id : id }).then((res) => {
        //  dispatch({
        //     type: DELETE_CUSTOMER,
        //     payload: res.data.result,
        // })
        return res;
    })
}

export const deleteAllCustomer = () => (dispatch) => {
    axios.post('/api/customer/delete-all').then((res) => {
        dispatch({
            type: DELETE_ALL_CUSTOMER,
            payload: res.data,
        })
    })
}

export const getCustomerById = (id) => async () => {
    return await axios.get('customers/find/' + id).then((res) => {
        return res.data;
    })
}

export const createCustomer = (customer) => async () => {
    return await axios.post('customers/create', customer).then((res) => {
        return res.data;
    })
}

export const updateCustomer = (customer) => async () => {
    return axios.post('customers/update', customer ).then((res) => {
        return res.data;
    })
}

export const updateCustomerImage = (customer) => async () => {
    return axios.post('customers/updateImage', customer).then((res) => {
        return res.data;
    })
}
import { ALL_EMAIL_CATEGORIES, ALL_EMAIL_MARKUPS, ALL_EMAIL_TEMPLATES, ALL_EMAIL_VARIABLES } from '../actions/MailTemplateActions'

const initialState = {
    categories: [],
    variables: [],
    markups: [],
    templates: []

}

const MailTemplateReducer = function (state = initialState, action) {
    switch (action.type) {
        case ALL_EMAIL_CATEGORIES: {
            return {
                ...state,
                categories: [...action.payload]
            }
        }
        case ALL_EMAIL_MARKUPS: {
            return {
                ...state,
                markups: [...action.payload]
            }
        }
        case ALL_EMAIL_TEMPLATES: {
            return {
                ...state,
                templates: [...action.payload]
            }
        }
        case ALL_EMAIL_VARIABLES: {
            return {
                ...state,
                variables: [...action.payload]
            }
        }
        default: {
            return {...state}
        }
    }
}

export default MailTemplateReducer

import { TAX_CLASSIFICATION_LIST } from '../actions/TaxClassificationAction'


const initialState = []


const TaxClassificationReducer = function (state = initialState, action) {
	switch (action.type) {
		case TAX_CLASSIFICATION_LIST: {
			return [...action.payload]
		}
		default: {
			return [...state]
		}
	}
}

export default TaxClassificationReducer

import axios from "./../../../axios"

export const GET_CARRIERS = 'GET_CARRIERS'
export const CREATE_CARRIERS = 'CREATE_CARRIERS'
export const DELETE_CARRIERS = 'DELETE_CARRIERS'
export const DELETE_ALL_CARRIERS = 'DELETE_ALL_CARRIERS'


export const getCarriers = () => (dispatch) => {
    axios.get('carrier/all').then((res) => {
        dispatch({
            type: GET_CARRIERS,
            payload: res.data.result,
        })
    })
}

export const createCarriers = (dispatch) => async () => {
    return axios.post('carrier/create', dispatch).then((res) => {
        return res.data;
    })
}

export const changeStatus = (data, id) => async () => {
    return axios.patch('carrier/change-status/' + id, data).then((res) => {
        return res.data;
    })
}


export const updateCarriers = (dispatch) => async () => {
    return axios.post('carrier/update', dispatch).then((res) => {
        return res.data;
    })
}

export const getCarriersById = (id) => (dispatch) => {
    return axios.get('carrier/find/' + id).then((res) => {
        return res.data;
    })
}

export const deleteCarriers = (id) => async () => {
    return axios.delete('carrier/delete/' + id).then((res) => {
        return res.data;
    })
}

export const massDelete = (dispatch) => async () => {
    return axios.post('carrier/mass-delete', dispatch).then((res) => {
        return res.data;
    })
}

export const massApproved = (data) => (dispatch) => {
    return axios.post('carrier/mass-approved', data).then((res) => {
        // dispatch({
        //     type: MARK_AS_READ,
        //     payload: res.data.result,
        // })
        return res.data.result;
    })
}

export const sortData = (data) => (dispatch) => {
    return axios.get('carrier/disapprove', data).then((res) => {
        dispatch({
            type: GET_CARRIERS,
            payload: res.data.result,
        })
    })
}


import SnackbarUtils from "app/general/SnackbarUtils"
import axios from "./../../../axios"

export const GET_ROLE = 'GET_ROLE'
export const CREATE_ROLE = 'CREATE_ROLE'
export const DELETE_ROLE = 'DELETE_ROLE'
export const DELETE_ALL_ROLE = 'DELETE_ALL_ROLE'

export const getRoles = () => (dispatch) => {
    axios.post('role/all').then((res) => {
        dispatch({
            type: GET_ROLE,
            payload: res.data.result,
        })
    })
}

export const getRole = (id) => (dispatch) => {
    return axios.get('role/get/' + id).then((res) => {

        return res.data;
    })
}


export const createRole = (role) => (dispatch) => {
    return axios.post('/role/create', role).then((res) => {

        return res.data
    })
}

export const updateRole = (role) => (dispatch) => {
    return axios.put('/role/update', role).then((res) => {

        return res.data
    })
}


export const deleteRole = (id) => (dispatch) => {
    return axios.delete('role/delete/' + id).then((res) => {

        if (res.data.bool) {
            SnackbarUtils.success(res.data.message);
        } else {
            SnackbarUtils.error(res.data.message);
        }
    })
}

export const setPermissionsByRole = (data, id) => (dispatch) => {
    return axios.post('permissions/update-permissions-by-role/' + id, data).then((res) => {
        
        if (res.data.bool) {
            SnackbarUtils.success(res.data.message);
        } else {
            SnackbarUtils.error(res.data.message);
        }
        return res.data;
    })
}
import React, { useState } from 'react'
import { Card, Grid, Button } from '@material-ui/core'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { Link, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { useDispatch } from 'react-redux'
import { checkEmailForgotPassword, checkEmailCode, resetPassword } from 'app/redux/actions/UserAction'
import Toaster from 'toaster'



// import { toast } from 'react-toastify'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    cardHolder: {
        background: '#1A2038',
    },
    card: {
        maxWidth: 800,
        borderRadius: 12,
        margin: '1rem',
    },
}))

const ForgotPassword = () => {
    const [state, setState] = useState({})
    const [action, setAction] = useState("check-email")
    const classes = useStyles()
    const history = useHistory();

    // hash start
    // const { isAuthenticated, isInitialised, socialLogin, forgotPassword, verifyCode } = useAuth();
    // const isLoggedIn = this.state.isLoggedIn;

    // const [newPassword, setNewPassword] = useState(false);
    // const [showPassConfirm, setshowPassConfirm] = useState(false);
    const [openToast, setOpenToast] = useState(false)
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    // hash end

    function handleToast(message, variant) {
        setState({
            ...state,
            message: message,
            variant: variant,
        })
        setOpenToast(true)
    }

    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        if (value !== change_password) {
            return false;
        }
        return true;
    });


    const handleChange = ({ target: { name, value } }) => {
        setState({
            ...state,
            [name]: value,
        })
    }

    const handlePassChange = (event) => {
        event.persist()
        setState({
            ...state,
            [event.target.name]: event.target.value,
        })
    }

    const handleFormSubmit = async (event) => {
        console.log(state.email)
        setLoading(true)
        const formDetail = new FormData();
        formDetail.append('email', state.email);
        await dispatch(checkEmailForgotPassword(formDetail)).then(response => {
            console.log(response)
            if (response.bool) {
                // toast.success('Success!')
                // console.log('successfuly done chack email')
                // setNewPassword(true)
                setAction('check-code')
                handleToast('Verification sent on your email  ', 'success')

            }
        }).catch(err => {
            // toast.success(err)
            handleToast(err.message, 'error')
            // console.log(' err printeddd')
            // console.log(err)
        })
        setLoading(false)
    }




    const submitHandlerOTP = async (event) => {
        setLoading(true)
        console.log(state.code)
        console.log('code')
        const formDetail = new FormData();
        formDetail.append('email', state.email);
        formDetail.append('code', state.code);
        await dispatch(checkEmailCode(formDetail)).then(response => {
            console.log(response)
            if (response.bool) {
                // toast.success('Success!')
                // setNewPassword(true)
                // setshowPassConfirm(true)
                setAction('change-password')
            }
        }).catch(err => {
            // toast.success(err)
            handleToast(err.message, 'error')
            // console.log(err)
        })
        setLoading(false)
    }



    const handlePassFormSubmit = async (event) => {
        console.log(state.change_password)
        console.log(state.confirm_password)
        setLoading(true)
        const formDetail = new FormData();
        formDetail.append('email', state.email);
        formDetail.append('password', state.change_password);
        formDetail.append('confirm_password', state.confirm_password);
        await dispatch(resetPassword(formDetail)).then(response => {
            console.log(response)
            if (response.bool) {
                // toast.success('Success!')
                console.log('All done g to login')
                history.push({
                	pathname: '/signin',
                })
            }
        }).catch(err => {
            // toast.success(err)
            console.log(err)
        })
        setLoading(false)
    }


    let { email } = state
    let { code } = state
    let { change_password } = state
    let { confirm_password } = state
    console.log(action)
    return (
        <div
            className={clsx(
                'flex justify-center items-center  min-h-full-screen',
                classes.cardHolder
            )}
        >

        <Toaster open={openToast} state={state} setOpen={setOpenToast} />

            <Card className={classes.card}>
                <Grid container>
                    <Grid item lg={5} md={5} sm={5} xs={12}>
                        <div className="p-8 flex justify-center items-center h-full">
                            <img
                                className="w-full"
                                src="/assets/images/illustrations/dreamer.svg"
                                alt=""
                            />
                        </div>
                    </Grid>

                    {
                        action === "check-code" &&

                        <Grid item lg={7} md={7} sm={7} xs={12}>
                            <div className="p-8 h-full bg-light-gray relative">

                                <ValidatorForm onSubmit={submitHandlerOTP}>
                                    <TextValidator
                                        className="mb-6 w-full"
                                        variant="outlined"
                                        label="Enter Code"
                                        onChange={handleChange}
                                        type="text"
                                        name="code"
                                        size="small"
                                        value={code || ''}
                                        validators={['required']}
                                        errorMessages={[
                                            'this field is required',
                                        ]}
                                    />
                                    <div className="flex items-center">
                                        <Button variant="contained" color="primary" type="submit" disabled={loading}>
                                            {
                                                loading ? 'Loading ... ' : "Continue"
                                            }
                                        </Button>
                                    </div>
                                </ValidatorForm>
                            </div>
                        </Grid>
                    }
                    {
                        action === "check-email" &&



                        <Grid item lg={7} md={7} sm={7} xs={12}>
                            <div className="p-8 h-full bg-light-gray relative">
                                <ValidatorForm onSubmit={handleFormSubmit}>
                                    <TextValidator
                                        className="mb-6 w-full"
                                        variant="outlined"
                                        label="Email"
                                        onChange={handleChange}
                                        type="email"
                                        name="email"
                                        size="small"
                                        value={email || ''}
                                        validators={['required', 'isEmail']}
                                        errorMessages={[
                                            'this field is required',
                                            'email is not valid',
                                        ]}
                                    />
                                    <div className="flex items-center">
                                        <Button
                                            disabled={loading}
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                        >
                                            {
                                                loading ? 'Loading ... ' : "Reset Password"
                                            }
                                        </Button>
                                        <span className="ml-4 mr-2">or</span>
                                        <Link to="/signin">
                                            <Button className="capitalize">
                                                Sign in
                                            </Button>
                                        </Link>
                                    </div>
                                </ValidatorForm>
                            </div>
                        </Grid>

                    }
                    {

                        action === "change-password" &&

                        <Grid item lg={7} md={7} sm={7} xs={12}>
                            <div className="p-8 h-full bg-light-gray relative">
                                <ValidatorForm onSubmit={handlePassFormSubmit}>
                                    <TextValidator
                                        className="mb-6 w-full"
                                        variant="outlined"
                                        label="Change Password (Min length 6)"
                                        onChange={handlePassChange}
                                        type="password"
                                        name="change_password"
                                        size="small"
                                        value={change_password || ""}
                                        validators={[
                                            'required',
                                            'minStringLength: 6',
                                            // 'maxStringLength: 15',
                                        ]}
                                        errorMessages={['this field is required']}
                                    />

                                    <TextValidator
                                        className="mb-6 w-full"
                                        variant="outlined"
                                        label="Confirm Password"
                                        onChange={handlePassChange}
                                        type="password"
                                        size="small"
                                        name="confirm_password"
                                        value={confirm_password}
                                        validators={[
                                            'required',
                                            'isPasswordMatch',
                                            'minStringLength: 6',
                                            // 'maxStringLength: 15',
                                        ]}
                                        errorMessages={['This field is required', 'Password Mismatch']}
                                    />

                                    <div className="flex items-center">
                                        <Button
                                            disabled={loading}
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                        >
                                            {
                                                loading ? 'Loading ... ' : "Change Password"
                                            }
                                        </Button>

                                    </div>
                                </ValidatorForm>
                            </div>
                        </Grid>
                    }

                    {/* <Grid item lg={7} md={7} sm={7} xs={12}>
                        <div className="p-8 h-full bg-light-gray relative">
                            <ValidatorForm onSubmit={handleFormSubmit}>
                                <TextValidator
                                    className="mb-6 w-full"
                                    variant="outlined"
                                    label="Email"
                                    onChange={handleChange}
                                    type="email"
                                    name="email"
                                    size="small"
                                    value={email || ''}
                                    validators={['required', 'isEmail']}
                                    errorMessages={[
                                        'this field is required',
                                        'email is not valid',
                                    ]}
                                />
                                <div className="flex items-center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        Reset Password
                                    </Button>
                                    <span className="ml-4 mr-2">or</span>
                                    <Link to="/signin">
                                        <Button className="capitalize">
                                            Sign in
                                        </Button>
                                    </Link>
                                </div>
                            </ValidatorForm>
                        </div>
                    </Grid> */}
                </Grid>
            </Card >
        </div >
    )
}

export default ForgotPassword

import axios from "./../../../axios"


export const GET_PORTABILITIES = 'GET_PORTABILITIES'
export const GET_PORTABILITIES_HISTORY = 'GET_PORTABILITIES_HISTORY'


export const getPortablility = () => (dispatch) => {
    axios.get('vendors/get-portabilities').then((res) => {
        dispatch({
            type: GET_PORTABILITIES,
            payload: res.data.result,
        })
    })
}

export const getPortablilityHistory = (id) => (dispatch) => {

    axios.post('vendors/get-portability-history', { 'id': id }).then((res) => {
        dispatch({
            type: GET_PORTABILITIES_HISTORY,
            payload: res.data.result,
        })
    })
}

export const checkPortablility = (data) => (dispatch) => {
    return axios.post('vendors/check-portability', data).then((res) => {
        return res.data;
    })
}

export const checkPortablilityInBulk = (data) => (dispatch) => {
    return axios.post('vendors/check-portability-bulk', data).then((res) => {
        return res.data;
    })
}

export const deletePortability = (data) => (dispatch) => {
    return axios.post('vendors/delete-portability', data).then((res) => {
        return res.data;
    })
}

export const recheckPortablility = (id) => (dispatch) => {
    return axios.post('vendors/recheck-portability/' + id).then((res) => {
        return res.data;
    })
}
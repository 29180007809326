import axios from "./../../../axios"

export const GET_LOGS = 'GET_LOGS'
export const GET_VENDORS = 'GET_VENDORS'
export const CHANGE_VENDOR_STATUS = 'CHANGE_VENDOR_STATUS'
export const UPDATE_VENDOR_SETTING = 'UPDATE_VENDOR_SETTING'
export const MANAGE_PRIORITY_STATUS = 'MANAGE_PRIORITY_STATUS'
export const UPDATE_API_SETTING = 'UPDATE_API_SETTING'
export const SEND_TESTING_MAIL = 'SEND_TESTING_MAIL'
export const MARK_AS_READ = 'MARK_AS_READ'


export const getLogs = () => (dispatch) => {
    axios.get('mail/mail-logs').then((res) => {
        dispatch({
            type: GET_LOGS,
            payload: res.data.result,
        })
    })
}

export const markAsRead = (log) => (dispatch) => {
    return axios.post('mail/mark-as-read', log).then((res) => {
        // dispatch({
        //     type: MARK_AS_READ,
        //     payload: res.data.result,
        // })
        return res.data.result;
    })
}


export const getVendors = () => (dispatch) => {
    return axios.get('mail/setting-list').then((res) => {
        dispatch({
            type: GET_VENDORS,
            payload: res.data.result,
        })
        return res.data.result;
    })
}

export const updateVendorSetting = (settings) => (dispatch) => {
    return axios.post('mail/setting-edit', settings).then((res) => {
        // dispatch({
        //     type: UPDATE_VENDOR_SETTING,
        //     payload: res.data.result,
        // })
        return res.data.result;
    })
}


export const changeVendorStatus = (vendor) => (dispatch) => {
    return axios.post('mail/set-active', vendor).then((res) => {
        return res.data;
    })
}

export const changePriority = (vendor) => (dispatch) => {

    axios.post('mail/set-priority', vendor).then((res) => {
        dispatch({
            type: MANAGE_PRIORITY_STATUS,
            payload: res.data.result,
        })
    })
}

export const sendTestingMail = (payload) => (dispatch) => {

    return axios.post('mail/send-mail', payload).then((res) => {
        // dispatch({
        //     type: SEND_TESTING_MAIL,
        //     payload: res.data.result,
        // })
        return res;
    })
}


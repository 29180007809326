import {
 GET_COUNTRIES,
} from '../actions/RegionAction';

const initialState = {
 getCountriesRegion: [],

}

const RegionReducer = (state = initialState, action) => {

 switch (action.type) {
     case GET_COUNTRIES:
         return {
             ...state,
             getCountriesRegion: [...action.payload]
         }
     default:
         return {
             ...state
         }
 }
}

export default RegionReducer
import React from 'react'
import useSettings from 'app/hooks/useSettings'

const MatxLogo = ({ className }) => {
    const { settings } = useSettings()
    const theme = settings.themes[settings.activeTheme]

    return (
       
        <div className="">
        <img
            className="sidebar-logo"
            src="/assets/images/pmp-logo-new.png"
            alt="Park My Phone"
        />
    </div>
    )
}

export default MatxLogo

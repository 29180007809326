import React, { createContext, useEffect, useReducer } from 'react'
// import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
// import jwt from 'jsonwebtoken'
// const JWT_SECRET = 'jwt_secret_key'
// const JWT_VALIDITY = '7 days'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

// const isValidToken = (accessToken) => {
//     if (!accessToken) {
//         return false
//     }

//     const decodedToken = jwtDecode(accessToken)
//     const currentTime = Date.now() / 1000
//     console.log(decodedToken)
//     return decodedToken.exp > currentTime
// }

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}
const setSessionId = (id) => {
    if (id) {
        localStorage.setItem('id', id);
    } else {
        localStorage.removeItem('id')
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload
            
            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => {},
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (email, password) => {
        const response = await axios.post('login', {
            email,
            password,
        });

        const userResult = response.data.result

        // const accessToken = jwt.sign({ id: userResult.id }, JWT_SECRET, {
        //     expiresIn: JWT_VALIDITY,
        // })

        const accessToken = userResult.token;

        if(userResult.avatar == null){
            userResult.avatar = '/assets/images/face-7.jpg'
        }

        const user = {
            id: userResult.id,
            avatar: userResult.avatar,
            email: userResult.email,
            name: userResult.name,
            role: userResult.role,
            token: accessToken,
            permissions: userResult.permissions
        };

        setSession(accessToken)
        setSessionId(userResult.id);

        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        })
    }

    const logout = async () => {
        
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
        };
        
        await axios.post('logout', {}, config).then((resp) => console.log(resp)).catch((resp) => console.log(resp));
        setSessionId(null);
        setSession(null)
        // const userResult = response.data.result
        // console.log(userResult);
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        ;(async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')

                if (accessToken) { // && isValidToken(accessToken)
                    setSession(accessToken)
                    
                    const response = await axios.get('/api/auth/profile')
                    const { user } = response.data

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext

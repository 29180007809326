import axios from "./../../../axios"

// Category

export const ALL_SMS_CATEGORIES = 'ALL_SMS_CATEGORIES'
export const ALL_SMS_VARIABLES = 'ALL_SMS_VARIABLES'
export const ALL_SMS_TEMPLATES = 'ALL_SMS_TEMPLATES'


// Category start

export const getCategories = () => (dispatch) => {
    axios.get('sms/category/all').then((res) => {
        dispatch({
            type: ALL_SMS_CATEGORIES,
            payload: res.data.result,
        })
    })
}
export const findCategory = (id) => async () => {
    return axios.get('sms/category/find/' + id).then((res) => {
        return res.data;
    })
}

export const createCategory = (category) => async () => {
    return axios.post('sms/category/create', category).then((res) => {
        return res.data;
    })
}

export const updateCategory = (category) => async () => {
    return axios.put('sms/category/update', category).then((res) => {
        return res.data;
    })
}

export const changeStatus = (data, id) => async () => {
    return axios.patch('sms/category/change-status/' + id, data).then((res) => {
        return res.data;
    })
}

export const deleteCategory = (id) => async () => {
    return axios.delete('sms/category/delete/' + id).then((res) => {
        return res.data;
    })
}

// Category end

// Variable start

export const getVariables = () => (dispatch) => {
    axios.get('sms/variable/all').then((res) => {
        dispatch({
            type: ALL_SMS_VARIABLES,
            payload: res.data.result,
        })
    })
}

export const findVariable = (id) => async () => {
    return axios.get('sms/variable/find/' + id).then((res) => {
        return res.data;
    })
}

export const createVariable = (category) => async () => {
    return axios.post('sms/variable/create', category).then((res) => {
        return res.data;
    })
}

export const updateVariable = (category) => async () => {
    return axios.put('sms/variable/update', category).then((res) => {
        return res.data;
    })
}

export const deleteVariable = (id) => async () => {
    return axios.delete('sms/variable/delete/' + id).then((res) => {
        return res.data;
    })
}

// Variable end

// Template start

export const getTemplates = () => (dispatch) => {
    axios.get('sms/template/all').then((res) => {
        dispatch({
            type: ALL_SMS_TEMPLATES,
            payload: res.data.result,
        })
    })
}

export const getSmsTemplateById = (id) => (dispatch) => {
    return axios.get('sms/template/get-by-id/' + id).then((res) => {
        return res.data;
    })
}

export const modifyTemplate = (data) => async () => {
    return axios.post('sms/template/modify', data).then((res) => {
        return res.data;
    })
}


export const changeTemplateStatus = (data, id) => async () => {
    return axios.patch('sms/template/change-status/' + id, data).then((res) => {
        return res.data;
    })
}

export const deleteTempate = (id) => async () => {
    return axios.delete('sms/template/delete/' + id).then((res) => {
        return res.data;
    })
}

// Template end
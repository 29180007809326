import React from 'react'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

const NotAllowed = () => {

	const heading = {
		fontSize: 160,
		fontWeight: 'bold',
		color: '#6c63ff'
	}

	return (
		<div className="flex justify-center items-center h-full-screen w-full">
			<div className="flex-column justify-center items-center max-w-320">
				<div style={heading}>403</div>
				<h4 className='text-center pb-2'>You are not allowed to view this page.</h4>
				<Link to="/">
					<Button
						className="capitalize"
						variant="contained"
						color="primary"
					>
						Back to Dashboard
					</Button>
				</Link>
			</div>
		</div>
	)
}

export default NotAllowed

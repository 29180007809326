import axios from "./../../../axios"
export const ALL_API_KEYS = 'ALL_API_KEYS'

export const getAllApiKeys = (id = null) => async (dispatch) => {
	if (id !== null) {
		axios.get(`token/${id}`).then((res) => {
			if (res.data.bool) {
				dispatch({
					type: ALL_API_KEYS,
					payload: res.data.result,
				})
			}
		})
	} else {
		axios.get(`token`).then((res) => {
			if (res.data.bool) {
				dispatch({
					type: ALL_API_KEYS,
					payload: res.data.result,
				})
			}
		})
	}
}

export const createAPIKey = (payload) => async () => {
	return axios.post('token', payload).then((res) => {
		return res.data;
	})
}

export const updateAPIKey = (payload, id) => async () => {
	return axios.put(`token/${id}`, payload).then((res) => {
		return res.data;
	})
}

export const revokeAPIKey = (id) => async () => {
	return axios.put(`token/revoke/${id}`).then((res) => {
		return res.data;
	})
}

export const deleteAPIKey = (id) => async () => {
	return axios.delete(`token/${id}`).then((res) => {
		return res.data;
	})
}

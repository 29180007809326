import {
    GET_QUESTIONNAIRE,

} from '../actions/QuestionnaireAction'

const initialState = []


const QuestionnaireReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_QUESTIONNAIRE: {
            return [...action.payload]
        }
    // case CREATE_CARRIERS: {
    //         return [...action.payload]
    //     }    
        default: {
            return [...state]
        }
    }
}

export default QuestionnaireReducer
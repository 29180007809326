import axios from "./../../../axios"

// Category

export const ALL_EMAIL_CATEGORIES = 'ALL_EMAIL_CATEGORIES'
export const ALL_EMAIL_VARIABLES = 'ALL_EMAIL_VARIABLES'
export const ALL_EMAIL_MARKUPS = 'ALL_EMAIL_MARKUPS'
export const ALL_EMAIL_TEMPLATES = 'ALL_EMAIL_TEMPLATES'

// Category start

export const getCategories = () => (dispatch) => {
    axios.get('mail/category/all').then((res) => {
        dispatch({
            type: ALL_EMAIL_CATEGORIES,
            payload: res.data.result,
        })
    })
}
export const findCategory = (id) => async () => {
    return axios.get('mail/category/find/' + id).then((res) => {
        return res.data;
    })
}

export const createCategory = (category) => async () => {
    return axios.post('mail/category/create', category).then((res) => {
        return res.data;
    })
}

export const updateCategory = (category) => async () => {
    return axios.put('mail/category/update', category).then((res) => {
        return res.data;
    })
}

export const changeStatus = (data, id) => async () => {
    return axios.patch('mail/category/change-status/' + id, data).then((res) => {
        return res.data;
    })
}

export const deleteCategory = (id) => async () => {
    return axios.delete('mail/category/delete/' + id).then((res) => {
        return res.data;
    })
}

// Category end

// Variable start

export const getVariables = () => (dispatch) => {
    axios.get('mail/variable/all').then((res) => {
        dispatch({
            type: ALL_EMAIL_VARIABLES,
            payload: res.data.result,
        })
    })
}

export const findVariable = (id) => async () => {
    return axios.get('mail/variable/find/' + id).then((res) => {
        return res.data;
    })
}

export const createVariable = (category) => async () => {
    return axios.post('mail/variable/create', category).then((res) => {
        return res.data;
    })
}

export const updateVariable = (category) => async () => {
    return axios.put('mail/variable/update', category).then((res) => {
        return res.data;
    })
}

export const deleteVariable = (id) => async () => {
    return axios.delete('mail/variable/delete/' + id).then((res) => {
        return res.data;
    })
}

// Variable end

// Markup start

export const getMarkups = () => (dispatch) => {
    axios.get('mail/markup/all').then((res) => {
        dispatch({
            type: ALL_EMAIL_MARKUPS,
            payload: res.data.result,
        })
    })
}
export const findMarkup = (id) => async () => {
    return axios.get('mail/markup/find/' + id).then((res) => {
        return res.data;
    })
}

export const createMarkup = (markup) => async () => {
    return axios.post('mail/markup/create', markup).then((res) => {
        return res.data;
    })
}

export const updateMarkup = (markup) => async () => {
    return axios.put('mail/markup/update', markup).then((res) => {
        return res.data;
    })
}


export const deleteMarkup = (id) => async () => {
    return axios.delete('mail/markup/delete/' + id).then((res) => {
        return res.data;
    })
}

// Markup end
// Template start

export const getTemplates = () => (dispatch) => {
    axios.get('mail/template/all').then((res) => {
        dispatch({
            type: ALL_EMAIL_TEMPLATES,
            payload: res.data.result,
        })
    })
}

export const getEmailTemplate = (category_id, markup_id) => (dispatch) => {
    return axios.get('mail/template/find',  { params: {'category_id': category_id, 'markup_id': markup_id }}).then((res) => {
        return res.data;
    })
}

export const getEmailTemplateById = (id) => (dispatch) => {
    return axios.get('mail/template/get-by-id/' + id).then((res) => {
        return res.data;
    })
}

export const modifyTemplate = (data) => async () => {
    return axios.post('mail/template/modify', data).then((res) => {
        return res.data;
    })
}


export const changeTemplateStatus = (data, id) => async () => {
    return axios.patch('mail/template/change-status/' + id, data).then((res) => {
        return res.data;
    })
}

export const deleteTempate = (id) => async () => {
    return axios.delete('mail/template/delete/' + id).then((res) => {
        return res.data;
    })
}


// Template end



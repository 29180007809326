import {
    CREATE_TOOLTIPS,
    GET_FORMLIST,
    GET_STEPLIST,
    GET_TOOLTIPS
} from '../actions/TooltipsAction';


const initialState = {
    getFormList: [],
    getStepList: [],
    getTooltips: [],

}

const TooltipsReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_FORMLIST: 
            return {
                ...state,
                getFormList: [...action.payload]
            }
        case GET_STEPLIST: 
            return {
                ...state,
                getStepList: [...action.payload]
            }
            case CREATE_TOOLTIPS: {
                return [...action.payload]
            }  
            case GET_TOOLTIPS: {
                return {
                    ...state,
                    getTooltips: [...action.payload]
                }
            }
        default: 
            return {
                ...state
            }
        
    }
}

export default TooltipsReducer

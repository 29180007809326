import axios from "../../../axios"
export const ALL_RECORDS = 'ALL_RECORDS'
export const SITE_LIST = 'SITE_LIST'

export const getBandwidthNumbers = (param) => async (dispatch) => {
	return axios.post(`vendors/bandwidth/list-tn`, param).then((res) => {
		dispatch({
			type: ALL_RECORDS,
			payload: res.data.result,
		})

		return res.data
	})
}

export const getTNDetails = (tn) => async (dispatch) => {
	return await axios.get(`vendors/bandwidth/tn-details/${tn}`).then((res) => {
		return res.data;
	})
}

// export const setMessagingStatus = (tn) => async (dispatch) => {
// 	return await axios.put(`vendors/bandwidth/update-sip-peer-tns-settings`, tn).then((res) => {
// 		return res.data;
// 	})
// }

export const getDidStatus = () => async (dispatch) => {
	return await axios.get(`did-status/list`).then((res) => {
		return res.data;
	})
}
export const getSiteList = () => async (dispatch) => {
	return await axios.get(`vendors/bandwidth-site-list`).then((res) => {
		dispatch({
			type: SITE_LIST,
			payload: res.data.result,
		})
	})
}
export const getSipPeerList = (siteId) => async (dispatch) => {
	return await axios.get(`vendors/bandwidth-sip-peers/${siteId}`).then((res) => {
		return res.data;
	})
}

export const updateSipPeerOfTn = (payload) => async (dispatch) => {
	return await axios.post(`vendors/bandwidth/move-location`, payload).then((res) => {
		return res.data;
	})
}

export const updateTnInBulk = (payload) => async (dispatch) => {
	return await axios.put(`vendors/bandwidth/tn-options`, payload).then((res) => {
		return res.data;
	})
}

export const bulkDisconnectNumbers = (payload) => async (dispatch) => {
	return await axios.post(`vendors/bandwidth/disconnect`, payload).then((res) => {
		return res.data;
	})
}
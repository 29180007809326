import {
    CREATE_GET_TOOLTIPSSTEPS,
    GET_TOOLTIPSFORM,
    GET_TOOLTIPSSTEPS
} from '../actions/TooltipsStepsAction';


const initialState = {
    getTooltipsSteps: [],
    getTooltipsForm: [],

}

const TooltipsReducerStepsReducer = (state = initialState, action) => {

    switch (action.type) {
            case GET_TOOLTIPSSTEPS: {
                return {
                    ...state,
                    getTooltipsSteps: [...action.payload]
                }
            }
            case GET_TOOLTIPSFORM: {
                return {
                    ...state,
                    getTooltipsForm: [...action.payload]
                }
            }
        default: 
            return {
                ...state
            }
        
    }
}

export default TooltipsReducerStepsReducer

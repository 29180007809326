import {
    GET_LOGS,
    GET_VENDORS
} from '../actions/MailManagementAction';


const initialState = {
    getLogs: [],
    getVendors: [],

}

const MailManagementReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_LOGS: 
            return {
                ...state,
                getLogs: [...action.payload]
            }
        case GET_VENDORS: 
            return {
                ...state,
                getVendors: [...action.payload]
            }
        default: 
            return {
                ...state
            }
        
    }
}

export default MailManagementReducer

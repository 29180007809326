import Login from './login/Login'
import NotFound from './NotFound'
import ForgotPassword from './ForgotPassword'
import ChangePassword from './ChangePassword'
import NotAllowed from './NotAllowed'

const authRoutes = [
	{
		path: '/signin',
		component: Login,
	},
	{
		path: '/session/forgot-password',
		component: ForgotPassword,
	},
	{
		path: '/session/404',
		component: NotFound,
	},
	{
		path: '/session/403',
		component: NotAllowed,
	},
	{
		path: '/change-password',
		component: ChangePassword,
	},
]

export default authRoutes

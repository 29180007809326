import React, { useState } from 'react'
import { Card, Grid, Button } from '@material-ui/core'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { Link , useHistory} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { useDispatch } from 'react-redux'
import { checkEmailForgotPassword, checkEmailCode} from 'app/redux/actions/UserAction'

// import { toast } from 'react-toastify'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    cardHolder: {
        background: '#1A2038',
    },
    card: {
        maxWidth: 800,
        borderRadius: 12,
        margin: '1rem',
    },
}))

const ForgotPassword = () => {
    const [state, setState] = useState({})
    const classes = useStyles()
    const history = useHistory();

    // hash start
    // const { isAuthenticated, isInitialised, socialLogin, forgotPassword, verifyCode } = useAuth();
        // const isLoggedIn = this.state.isLoggedIn;
        const [loading, setLoading] = useState(false);
        const dispatch = useDispatch();
    // hash end

    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        if (value !== change_password) {
            return false;
        }
        return true;
    });

    const handleChange = (event) => {
        event.persist()
        setState({
            ...state,
            [event.target.name]: event.target.value,
        })
    }

    const handleFormSubmit = async (event) => {
        console.log(state.change_password)
        console.log(state.confirm_password)
        setLoading(true)
        const formDetail = new FormData();
        formDetail.append('email', state.email);
        await dispatch(checkEmailForgotPassword(formDetail)).then(response => {
            console.log(response)
            if(response.bool){
                // toast.success('Success!')
                console.log('successfuly done chack email')
            }
        }).catch(err => {
			// toast.success(err)
			console.log(err)
		})
		setLoading(false)
    }




    let { change_password } = state
    let { confirm_password } = state

    return (
        <div
            className={clsx(
                'flex justify-center items-center  min-h-full-screen',
                classes.cardHolder
            )}
        >
            <Card className={classes.card}>
                <Grid container>
                    <Grid item lg={5} md={5} sm={5} xs={12}>
                        <div className="p-8 flex justify-center items-center h-full">
                            <img
                                className="w-full"
                                src="/assets/images/illustrations/dreamer.svg"
                                alt=""
                            />
                        </div>
                    </Grid>

                            <Grid item lg={7} md={7} sm={7} xs={12}>
                            <div className="p-8 h-full bg-light-gray relative">
                                <ValidatorForm onSubmit={handleFormSubmit}>
                                    <TextValidator
                                        className="mb-6 w-full"
                                        variant="outlined"
                                        label="Change Password (Min length 6)"
                                        onChange={handleChange}
                                        type="password"
                                        name="change_password"
                                        value={change_password || ""}
                                        validators={[
                                            'required',
                                            'minStringLength: 6',
                                            // 'maxStringLength: 15',
                                        ]}
                                        errorMessages={['this field is required']}
                                    />

                                    <TextValidator
                                        className="mb-6 w-full"
                                        variant="outlined"
                                        label="Confirm Password"
                                        onChange={handleChange}
                                        type="password"
                                        name="confirm_password"
                                        value={confirm_password}
                                        validators={[
                                            'required',
                                            'isPasswordMatch',
                                            'minStringLength: 6',
                                            // 'maxStringLength: 15',
                                        ]}
                                        errorMessages={['This field is required', 'Password Mismatch']}
                                    />



                                    <div className="flex items-center">
                                        <Button
                                            disabled={loading}
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                        >
                                           {
												loading ? 'Loading ... ' : "Change Password"
											}
                                        </Button>

                                    </div>
                                </ValidatorForm>
                            </div>
                        </Grid>




                </Grid>
            </Card>
        </div>
    )
}

export default ForgotPassword

import React from 'react'
import { Redirect } from 'react-router-dom'

import dashboardRoutes from './views/dashboard/DashboardRoutes'
import pagesRoutes from './views/pages/pagesRoutes'
import pageLayoutRoutes from './views/profile/ProfileRoutes'


const redirectRoute = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/dashboard/default" />,
    },
]

const errorRoute = [
    {
        component: () => <Redirect to="/session/404" />,
    },
]

const routes = [
    ...pageLayoutRoutes,
    ...dashboardRoutes,
    ...pagesRoutes,
    ...redirectRoute,
    ...errorRoute,
]

export default routes

import SnackbarUtils from "app/general/SnackbarUtils"
import axios from "./../../../axios"

export const GET_COUNTRIES = 'GET_COUNTRIES'
export const NUMBER_LIST = 'NUMBER_LIST'
export const CLEAR_LIST = 'CLEAR_LIST'
export const SUB_DIVISION_BY_COUNTRY = 'SUB_DIVISION_BY_COUNTRY'


export const getCountries = () => (dispatch) => {
    axios.get('countries/all').then((res) => {
        dispatch({
            type: GET_COUNTRIES,
            payload: res.data.result,
        })
    })
}
export const getDivisionsByCountryId = (id) => (dispatch) => {
    axios.get('countries/division/' + id).then((res) => {
        dispatch({
            type: SUB_DIVISION_BY_COUNTRY,
            payload: res.data.result,
        })
    })
}

export const checkNumber = (body) => (dispatch) => {
    return axios.post('new-number/search', body).then((res) => {
        dispatch({
            type: NUMBER_LIST,
            payload: res.data.result,
        })
    }).catch(error => {

        SnackbarUtils.info("No Number Found Matching Your Search Criteria, Please try another search");
        dispatch({
            type: CLEAR_LIST
        })
    })
}

export const clearNumberList = () => (dispatch) => {
    dispatch({
        type: CLEAR_LIST
    })
}


import axios from "./../../../axios"

export const GET_TOOLTIPSSTEPS = 'GET_TOOLTIPSSTEPS'
export const GET_TOOLTIPSFORM = 'GET_TOOLTIPSFORM'
export const CREATE_TOOLTIPSSTEPS = 'CREATE_TOOLTIPSSTEPS'
export const DELETE_TOOLTIPSSTEPS = 'DELETE_TOOLTIPSSTEPS'
export const DELETE_ALL_TOOLTIPSSTEPS = 'DELETE_ALL_TOOLTIPSSTEPS'



export const getTooltipsSteps = () => (dispatch) => {
    axios.get('steps/all').then((res) => {
        dispatch({
            type: GET_TOOLTIPSSTEPS,
            payload: res.data.result,
        })
    })
}


export const getTooltipsFormName = () => (dispatch) => {
    axios.get('tooltip-forms/all').then((res) => {
        dispatch({
            type: GET_TOOLTIPSFORM,
            payload: res.data.result,
        })
    })
}

export const createTooltipsSteps = (dispatch) => async () => {
    return axios.post('steps/create', dispatch).then((res) => {
        return res.data;
    })
}


// export const changeStatus = (data, id) => async () => {
//     return axios.patch('carrier/change-status/' + id, data).then((res) => {
//         return res.data;
//     })
// }


export const updateTooltipsSteps = (dispatch) => async () => {
    return axios.post('steps/update', dispatch).then((res) => {
        return res.data;
    })
}

export const getTooltipsByIdSteps = (id) => (dispatch) => {
    return axios.get('steps/show/' + id).then((res) => {
        return res.data;
    })
}

export const deleteTooltipsSteps = (id) => async () => {
    return axios.delete('steps/delete/' + id).then((res) => {
        return res.data;
    })
}

// export const massDelete = (dispatch) => async () => {
//     return axios.post('steps/mass-delete', dispatch).then((res) => {
//         return res.data;
//     })
// }

// export const massApproved = (data) => (dispatch) => {
//     return axios.post('steps/mass-approved', data).then((res) => {
//         // dispatch({
//         //     type: MARK_AS_READ,
//         //     payload: res.data.result,
//         // })
//         return res.data.result;
//     })
// }

// export const sortData = (data) => (dispatch) => {
//     return axios.get('steps/disapprove', data).then((res) => {
//         dispatch({
//             type: GET_TOOLTIPSSTEPS,
//             payload: res.data.result,
//         })
//     })
// }


import Mock from '../mock'
import jwt from 'jsonwebtoken'
import axios from 'axios.js'

const JWT_SECRET = 'jwt_secret_key'

Mock.onGet('/api/auth/profile').reply(async (config) => {
	await new Promise((resolve) => setTimeout(resolve, 1000))
	try {

		const { Authorization } = config.headers
		const id = localStorage.getItem('id');
		if (!Authorization) {
			return [401, { message: 'Invalid Authorization token' }]
		}
		const accessToken = Authorization.split(' ')[1];
		// const { id } = jwt.verify(accessToken, JWT_SECRET)
		// if(id != null){
		const response = await axios.post('auth-admin', { 'id': id });
		var user = response.data.result;

		if (user.avatar == null) {
			user.avatar = '/assets/images/face-7.jpg'
		}
		// }

		if (!user) {
			return [401, { message: 'Invalid authorization token' }]
		}

		return [
			200,
			{
				user: {
					id: user.id,
					avatar: user.avatar,
					email: user.email,
					name: user.first_name + " " + user.last_name,
					role: JSON.stringify(user.role),
					permissions: JSON.stringify(user.permissions),
				},
			},
		]
	} catch (err) {
		console.error(err)
		return [500, { message: 'Internal server error' }]
	}
})

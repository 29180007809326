import axios from "./../../../axios"

// Plans Category | Features
export const ALL_PLANS_CATEGORIES = 'ALL_PLANS_CATEGORIES'
export const ALL_PLANS_FEATURES = 'ALL_PLANS_FEATURES'
export const ALL_PLANS_ADDONS = 'ALL_PLANS_ADDONS'
export const ALL_PLANS = 'ALL_PLANS'



// Category start
export const getCategories = (getAll = false) => (dispatch) => {
	axios.get(`plans/category/all/${getAll}`).then((res) => {
		dispatch({
			type: ALL_PLANS_CATEGORIES,
			payload: res.data.result,
		})
	})
}
export const findCategory = (id) => async () => {
	return axios.get('plans/category/find/' + id).then((res) => {
		return res.data;
	})
}

export const createCategory = (category) => async () => {
	return axios.post('plans/category/create', category).then((res) => {
		return res.data;
	})
}

export const updateCategory = (category) => async () => {
	return axios.put('plans/category/update', category).then((res) => {
		return res.data;
	})
}

export const changeStatus = (data, id) => async () => {
	return axios.patch('plans/category/change-status/' + id, data).then((res) => {
		return res.data;
	})
}

export const deleteCategory = (id) => async () => {
	return axios.delete('plans/category/delete/' + id).then((res) => {
		return res.data;
	})
}
// Category end
// ----------------------------------------------------------
// Features start
export const getFeatures = () => (dispatch) => {
	axios.get('plans/feature/all').then((res) => {
		dispatch({
			type: ALL_PLANS_FEATURES,
			payload: res.data.result,
		})
	})
}
export const findFeature = (id) => async () => {
	return axios.get('plans/feature/find/' + id).then((res) => {
		return res.data;
	})
}

export const createFeature = (feature) => async () => {
	return axios.post('plans/feature/create', feature).then((res) => {
		return res.data;
	})
}

export const updateFeature = (feature) => async () => {
	return axios.put('plans/feature/update', feature).then((res) => {
		return res.data;
	})
}

export const changeFeaturStatus = (data, id) => async () => {
	return axios.patch('plans/feature/change-status/' + id, data).then((res) => {
		return res.data;
	})
}

export const deleteFeature = (id) => async () => {
	return axios.delete('plans/feature/delete/' + id).then((res) => {
		return res.data;
	})
}
// Features end
// ----------------------------------------------------------
// Add-Ons start
export const getAddOns = () => (dispatch) => {
	axios.get('plans/add-ons/all').then((res) => {
		dispatch({
			type: ALL_PLANS_ADDONS,
			payload: res.data.result,
		})
	})
}
export const findAddOn = (id) => async () => {
	return axios.get('plans/add-ons/find/' + id).then((res) => {
		return res.data;
	})
}

export const createAddOn = (feature) => async () => {
	return axios.post('plans/add-ons/create', feature).then((res) => {
		return res.data;
	})
}

export const updateAddOn = (feature) => async () => {
	return axios.put('plans/add-ons/update', feature).then((res) => {
		return res.data;
	})
}


export const deleteAddOn = (id) => async () => {
	return axios.delete('plans/add-ons/delete/' + id).then((res) => {
		return res.data;
	})
}
// Add-Ons end
// ----------------------------------------------------------
// Plans start

export const allPlans = (id) => async (dispatch) => {
	return axios.get('plans/all').then((res) => {
		dispatch({
			type: ALL_PLANS,
			payload: res.data.result,
		})
	})
}
export const allPlansWithClassifications = (id) => async (dispatch) => {
	return axios.get('tax-classification/plans-with-classification').then((res) => {
		dispatch({
			type: ALL_PLANS,
			payload: res.data.result,
		})
	})
}

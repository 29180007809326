import React from 'react'
const dashboardRoutes = [
    {
        path: '/dashboard/default',
        component: React.lazy(() => import('./Dashboard')),
        auth: ['admin','member'],
    },
]

export default dashboardRoutes

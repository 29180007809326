import axios from "./../../../axios"

export const GET_COUNTRIES = 'GET_COUNTRIES'



export const getCountriesRegion = () => (dispatch) => {
    axios.get('countries/allonce').then((res) => {
     console.log(res);
        dispatch({
            type: GET_COUNTRIES,
            payload: res.data.result,
        })
    })
}


export const changeStatus = (data, id) => async () => {
 return axios.patch('countries/change-status/' + id, data).then((res) => {
     return res.data;
 })
}

import { GET_SETTING_BY_APP, GET_SETTING_LIST, UPDATE_SETTING } from "../actions/SettingActions";

const initialState = {
    settingList: [],
    settingByApp: [],
}

const settingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SETTING_LIST:
            return {
                ...state,
                settingList: [...action.payload]
            }
        case GET_SETTING_BY_APP:
            return {
                ...state,
                settingByApp: [...action.payload]
            }
        case UPDATE_SETTING:
            return {
                ...state,
                settingList: [...action.payload]
            }
        default:
            return {
                ...state,
            }

    }
}

export default settingReducer;


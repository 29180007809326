import React from 'react'

const pagesRoutes = [
	// customers
	{
		path: '/pages/customer-list',
		component: React.lazy(() => import('./customers/CustomerList')),
	},
	{
		path: '/pages/customer/add',
		component: React.lazy(() =>
			import('./customers/customer-add/CustomerAdd')
		),
	},
	{
		path: '/pages/customer/edit/:id',
		component: React.lazy(() =>
			import('./customers/customer-add/CustomerAdd')
		),
	},
	{
		path: '/pages/view-customer/:id',
		component: React.lazy(() =>
			import('./customers/customer-viewer/CustomerViewer')
		),
	},
	// Service Logs
	{
		path: '/pages/service-activity',
		exact: true,
		component: React.lazy(() =>
			import('./service-activity/Index')
		),
	},
	{
		path: '/pages/service-activity/:id',
		component: React.lazy(() =>
			import('./service-activity/View')
		),
	},
	// Orders
	{
		path: '/pages/orders',
		component: React.lazy(() => import('./orders/OrderList')),
	},
	{
		path: '/pages/order/add',
		component: React.lazy(() => import('./orders/OrderAdd')),
	},
	{
		path: '/pages/order/edit/:id',
		component: React.lazy(() => import('./orders/OrderEdit')),
	},
	// Orders Status
	{
		path: '/pages/order/status',
		exact: true,
		component: React.lazy(() => import('./orders/order-status/Index')),
	},
	{
		path: '/pages/order/status/edit/:id',
		exact: true,
		component: React.lazy(() => import('./orders/order-status/Index')),
	},
	// users
	{
		path: '/pages/internal-users',
		component: React.lazy(() => import('./users/UserList')),
	},
	{
		path: '/pages/user/add',
		component: React.lazy(() => import('./users/UserAdd')),
	},
	{
		path: '/pages/user/edit/:id',
		component: React.lazy(() => import('./users/UserEdit')),
	},

	// Mail
	{
		path: '/pages/mail/category',
		exact: true,
		component: React.lazy(() => import('./mail/category/Category')),
	},
	{
		path: '/pages/mail/category/edit/:id',
		exact: true,
		component: React.lazy(() => import('./mail/category/Category')),
	},
	{
		path: '/pages/mail/variable',
		exact: true,
		component: React.lazy(() => import('./mail/variable/Variables')),
	},
	{
		path: '/pages/mail/variable/edit/:id',
		exact: true,
		component: React.lazy(() => import('./mail/variable/Variables')),
	},
	{
		path: '/pages/mail/theme',
		exact: true,
		component: React.lazy(() => import('./mail/theme/Themes')),
	},
	{
		path: '/pages/mail/theme/add',
		exact: true,
		component: React.lazy(() => import('./mail/theme/sub-components/Add')),
	},
	{
		path: '/pages/mail/theme/edit/:id',
		exact: true,
		component: React.lazy(() => import('./mail/theme/sub-components/Edit')),
	},
	{
		path: '/pages/mail/template',
		exact: true,
		component: React.lazy(() => import('./mail/template/TemplateList')),
	},
	{
		path: '/pages/mail/template/add',
		exact: true,
		component: React.lazy(() => import('./mail/template/sub-components/TemplateAdd')),
	},
	{
		path: '/pages/mail/template/edit/:id',
		exact: true,
		component: React.lazy(() => import('./mail/template/sub-components/TemplateEdit')),
	},
	{
		path: '/pages/mail/settings',
		exact: true,
		component: React.lazy(() => import('./mail/settings/MailSettings')),
	},
	// SMS
	// Category
	{
		path: '/pages/sms/category',
		exact: true,
		component: React.lazy(() => import('./sms/category/Category')),
	},
	{
		path: '/pages/sms/category/edit/:id',
		exact: true,
		component: React.lazy(() => import('./sms/category/Category')),
	},
	// Variables
	{
		path: '/pages/sms/variable',
		exact: true,
		component: React.lazy(() => import('./sms/variable/Variables')),
	},
	{
		path: '/pages/sms/variable/edit/:id',
		exact: true,
		component: React.lazy(() => import('./sms/variable/Variables')),
	},
	// Templates
	{
		path: '/pages/sms/templates',
		exact: true,
		component: React.lazy(() => import('./sms/templates/TemplateList')),
	},
	{
		path: '/pages/sms/template/add',
		exact: true,
		component: React.lazy(() => import('./sms/templates/sub-components/TemplateAdd')),
	},
	{
		path: '/pages/sms/template/edit/:id',
		exact: true,
		component: React.lazy(() => import('./sms/variable/Variables')),
	},

	// General Settings
	{
		path: '/pages/settings/general',
		component: React.lazy(() => import('./settings/general/SettingList')),
	},
	{
		path: '/pages/settings/roles',
		exact: true,
		component: React.lazy(() => import('./settings/roles/Index')),
	},
	{
		path: '/pages/settings/roles/:id',
		exact: true,
		component: React.lazy(() => import('./settings/roles/Index')),
	},
	{
		path: '/pages/settings/permission/role',
		exact: true,
		component: React.lazy(() => import('./settings/roles/AssignPermissions')),
	},
	{
		path: '/pages/settings/permissions',
		exact: true,
		component: React.lazy(() => import('./settings/permissions/Index')),
	},
	{
		path: '/pages/settings/permissions/:id',
		exact: true,
		component: React.lazy(() => import('./settings/permissions/Index')),
	},
	{
		path: '/pages/settings/emails',
		component: React.lazy(() => import('./settings/emails/Index')),
	},
	{
		path: '/pages/settings/emails/edit/:id',
		component: React.lazy(() => import('./settings/emails/Index')),
	},
	{
		path: '/pages/settings/api-authentication',
		component: React.lazy(() => import('./settings/api-authentication/Index')),
	},
	// portability

	{
		path: '/pages/portability',
		component: React.lazy(() => import('./portability/Index')),
	},
	// New number
	{
		path: '/pages/new-number',
		component: React.lazy(() => import('./new-number/Index')),
	},
	// Carriers
	{
		path: '/pages/carriers',
		exact: true,
		component: React.lazy(() => import('./carriers/List')),
	},
	{
		path: '/pages/carriers/add',
		exact: true,
		component: React.lazy(() => import('./carriers/Add')),
	},
	{
		path: '/pages/carriers/edit/:id',
		component: React.lazy(() => import('./carriers/Edit')),
	},
	// Financials
	{
		path: '/pages/financial/tax-classification',
		exact: true,
		component: React.lazy(() => import('./financials/tax-classification/List')),
	},
	{
		path: '/pages/financial/assign/:id',
		exact: true,
		component: React.lazy(() => import('./financials/tax-classification/Assign')),
	},
	{
		path: '/pages/financial/tax-classification/:id',
		exact: true,
		component: React.lazy(() => import('./financials/tax-classification/List')),
	},
	{
		path: '/pages/financial/income-statement',
		component: React.lazy(() => import('./financials/income-statements/IncomeStatement')),
	},
	// Plans
	{
		path: '/pages/plans/list',
		exact: true,
		component: React.lazy(() => import('./plans/plans/List')),
	},
	{
		path: '/pages/plans/card-ui',
		exact: true,
		component: React.lazy(() => import('./plans/plans-card-ui/Index')),
	},
	{
		path: '/pages/plans/add',
		exact: true,
		component: React.lazy(() => import('./plans/plans/sub-components/Add')),
	},

	//Plans Category
	{
		path: '/pages/plans/plans-categories',
		exact: true,
		component: React.lazy(() => import('./plans/plans-categories/List')),
	},
	{
		path: '/pages/plans/plans-categories/edit/:id',
		exact: true,
		component: React.lazy(() => import('./plans/plans-categories/List')),
	},
	//Plans Features
	{
		path: '/pages/plans/plans-features',
		exact: true,
		component: React.lazy(() => import('./plans/plans-features/List')),
	},
	{
		path: '/pages/plans/plans-features/edit/:id',
		exact: true,
		component: React.lazy(() => import('./plans/plans-features/List')),
	},
	// questionnaire
	{
		path: '/pages/questionnaire',
		exact: true,
		component: React.lazy(() => import('./questionnaire/List')),
	},
	{
		path: '/pages/questionnaire/add',
		exact: true,
		component: React.lazy(() => import('./questionnaire/Add')),
	},
	//Plans Add Ons
	{
		path: '/pages/plans/plan-add-ons',
		exact: true,
		component: React.lazy(() => import('./plans/plan-add-ons/List')),
	},
	{
		path: '/pages/plans/plan-add-ons/edit/:id',
		exact: true,
		component: React.lazy(() => import('./plans/plan-add-ons/List')),
	},
	{
		path: '/pages/questionnaire/edit/:id',
		exact: true,
		component: React.lazy(() => import('./questionnaire/Edit')),
	},
	{
		path: '/pages/tooltips/add',
		exact: true,
		component: React.lazy(() => import('./tooltips/Add')),
	},
	{
		path: '/pages/tooltips',
		exact: true,
		component: React.lazy(() => import('./tooltips/List')),
	},
	{
		path: '/pages/tooltips/edit/:id',
		exact: true,
		component: React.lazy(() => import('./tooltips/Edit')),
	},
	{
		path: '/pages/tooltips/steps',
		exact: true,
		component: React.lazy(() => import('./tooltips/steps/steps')),
	},
	{
		path: '/pages/tooltips/form',
		exact: true,
		component: React.lazy(() => import('./tooltips/form/form')),
	},
	{
		path: '/pages/tooltips/form/edit/:id',
		exact: true,
		component: React.lazy(() => import('./tooltips/form/form')),
	},
	{
		path: '/pages/tooltips/steps/edit/:id',
		exact: true,
		component: React.lazy(() => import('./tooltips/steps/steps')),
	},
	{
		path: '/pages/tooltips/step-data/view/:id',
		exact: true,
		component: React.lazy(() => import('./tooltips/steps/StepsTablebyId')),
	},
	{
		path: '/pages/tooltips/tooltip-data/view/:id',
		exact: true,
		component: React.lazy(() => import('./tooltips/steps/TooltipTablebyId')),
	},
	{
		path: '/pages/tooltips/tooltip-data-forms/view/:id',
		exact: true,
		component: React.lazy(() => import('./tooltips/steps//TooltipTablebyIdOntooltip')),
	},

	/* Reports */
	{
		path: '/pages/reports/customer',
		component: React.lazy(() => import('./reports/CustomerReport')),
	},
	{
		path: '/pages/settings/region',
		component: React.lazy(() => import('./settings/region/RegionList')),
	},
	/* Reports end */

	{
		path: '/pages/promotion/theme',
		exact: true,
		component: React.lazy(() => import('./promotion/Themes.jsx')),
	},
	{
		path: '/pages/promotion/add',
		exact: true,
		component: React.lazy(() => import('./promotion/sub-components/Add')),
	},
	{
		path: '/pages/promotion/theme/edit/:id',
		exact: true,
		component: React.lazy(() => import('./promotion/sub-components/Edit')),
	},

	/* Did start */

	{
		path: '/pages/did/bandwidth-numbers/list',
		exact: true,
		component: React.lazy(() => import('./dids/bandwidth-numbers/List'))
	},
	/* Did end */
]

export default pagesRoutes

import {
	GET_USER,
	CREATE_USER,
	DELETE_ALL_USER,
	DELETE_USER,
} from '../actions/UserAction'

const initialState = {}

const UserReducer = function (state = initialState, action) {
	switch (action.type) {
		case GET_USER: {
			return { ...action.payload }
		}
		case CREATE_USER: {
			return { ...action.payload }
		}
		case DELETE_USER: {
			return state.filter((item) => item.id !== action.payload)
		}
		case DELETE_ALL_USER: {
			return { ...action.payload }
		}
		default: {
			return { ...state }
		}
	}
}

export default UserReducer

import axios from "./../../../axios"
export const ALL_SERVICE_LOGS = 'ALL_SERVICE_LOGS'
export const VIEW_SERVICE_LOG = 'VIEW_SERVICE_LOG'

export const allServiceLogs = (args = "") => async (dispatch) => {
	axios.get(`customer-services/all${args}`).then((res) => {

		if (res.data.bool) {
			dispatch({
				type: ALL_SERVICE_LOGS,
				payload: res.data.result,
			})
		}
	})
}

export const serviceLogById = (id) => async (dispatch) => {
	return axios.get(`customer-services/find/${id}`).then(({ data: { bool, result } }) => {
		if (bool) {
			dispatch({
				type: VIEW_SERVICE_LOG,
				payload: result,
			})
			return bool
		}
	})
}

export const searchServiceLogs = (payload) => async (dispatch) => {
	return axios.post(`customer-services/search`, payload).then((res) => {
		if (res.data.bool) {
			dispatch({
				type: ALL_SERVICE_LOGS,
				payload: res.data.result,
			})
			return true;
		}
	})
}

import {
    GET_PERMISSION,
    CREATE_PERMISSION,
    DELETE_ALL_PERMISSION,
    DELETE_PERMISSION,
} from '../actions/PermissionAction'

const initialState = []

const PermissionReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_PERMISSION: {
            return [...action.payload]
        }
        case CREATE_PERMISSION: {
            return [...action.payload]
        }
        case DELETE_PERMISSION: {
            return [...action.payload]
        }
        case DELETE_ALL_PERMISSION: {
            return [...action.payload]
        }
        default: {
            return [...state]
        }
    }
}

export default PermissionReducer

import { ALL_SMS_CATEGORIES, ALL_SMS_VARIABLES } from '../actions/SMSTemplateAction'


const initialState = {
    categories: [],
    variables: [],
}


const SMSManagementReducer = function (state = initialState, action) {
    switch (action.type) {
        case ALL_SMS_CATEGORIES: {
            return {
                ...state,
                categories: [...action.payload]
            }
        }
        case ALL_SMS_VARIABLES: {
            return {
                ...state,
                variables: [...action.payload]
            }
        }
        default: {
            return {...state}
        }
    }
}

export default SMSManagementReducer
import React, { useState } from 'react'
import {
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    Button,
    CircularProgress,
} from '@material-ui/core'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import history from 'history.js'
import clsx from 'clsx'
import useAuth from 'app/hooks/useAuth'
import SetTitle from "app/general/Title"
import useStyles from "./utils"

const Login = () => {
    const [btnLoading, setButtonLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [userInfo, setUserInfo] = useState({
        email: '',
        password: '',
        remember: false,
    })
    const classes = useStyles()
    const { login } = useAuth()

    const handleChange = ({ target: { name, value } }) => {
        let temp = { ...userInfo }
        temp[name] = value
        setUserInfo(temp)
    }

    const handleFormSubmit = async () => {
        setButtonLoading(true)
        try {
            await login(userInfo.email, userInfo.password);
            
            history.push('/')
        } catch (e) {
            setErrorMessage(e.message)
            setButtonLoading(false)
        }
    }
    return (
        <div
            className={clsx(
                'flex justify-center items-center  min-h-full-screen',
                classes.cardHolder
            )}
        >
        <SetTitle title={`Login Page`}/>
            <Card className={classes.card}>
                <Grid container>
                    <Grid item lg={6} md={6} sm={5} xs={12}>
                        <div
                            className={clsx({
                                'py-8 px-14 h-full': true,
                                [classes.cardLeft]: true,
                            })}
                        >
                            <div className="mb-5">
                                <img
                                    className="login-logo"
                                    src="/assets/images/pmp-logo-new.png"
                                    alt="Park My Phone"
                                />
                            </div>
                            <h1 className={classes.mainTitle}>
                                Administration Panel
                            </h1>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>

                        <div className="p-8 h-full relative">

                            <ValidatorForm onSubmit={handleFormSubmit}>
                                <TextValidator
                                    className="mb-6 w-full"
                                    variant="outlined"
                                    size="small"
                                    label="Email"
                                    onChange={handleChange}
                                    type="email"
                                    name="email"
                                    value={userInfo.email}
                                    validators={['required', 'isEmail']}
                                    errorMessages={[
                                        'this field is required',
                                        'email is not valid',
                                    ]}
                                />
                                <TextValidator
                                    className="mb-3 w-full"
                                    label="Password"
                                    variant="outlined"
                                    size="small"
                                    onChange={handleChange}
                                    name="password"
                                    type="password"
                                    value={userInfo.password}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                />

                                {errorMessage && (
                                    <p className="text-error">{errorMessage}</p>
                                )}
                                <FormControlLabel
                                    className="mb-3 min-w-288"
                                    name="remember"
                                    onChange={handleChange}
                                    control={
                                        <Checkbox
                                            size="small"
                                            onChange={({
                                                target: { checked },
                                            }) =>
                                                handleChange({
                                                    target: {
                                                        name: 'remember',
                                                        value: checked,
                                                    },
                                                })
                                            }
                                            checked={userInfo.remember}
                                        />
                                    }
                                    label="Remeber me"
                                />

                                <div className="flex flex-wrap items-center mb-4">
                                    <div className="relative">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={btnLoading}
                                            type="submit"
                                        >
                                            Sign in
                                        </Button>
                                        {btnLoading && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                                <Button
                                    className="text-primary"
                                    onClick={() =>
                                        history.push('/session/forgot-password')
                                    }
                                >
                                    Forgot password?
                                </Button>
                            </ValidatorForm>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </div>
    )
}

export default Login

import { combineReducers } from 'redux'
import NotificationReducer from './NotificationReducer'
import UserReducer from './UserReducer'
import CustomerReducer from './CustomerReducer'
import PermissionReducer from './PermissionReducer'
import RoleReducer from './RoleReducer'
import SettingReducer from './SettingReducer'
import MailManagementReducer from './MailManagementReducer'
import MailTemplateReducer from './MailTemplateReducer'
import LogReducer from './LogReducer'
import VendorReducer from './VendorReducer'
import CarriersReducer from './CarriersReducer'
import QuestionnaireReducer from './QuestionnaireReducer'
import SMSManagementReducer from './SMSManagementReducer'
import PlansManagementReducer from './PlansManagementReducer'
import OrderReducer from './OrderReducer'
import NewNumberReducer from './NewNumberReducer'
import TooltipsReducer from './TooltipsReducer'
import TooltipsReducerStepsReducer from './TooltipsStepsReducer'
import TooltipsReducerFormReducer from './TooltipsFormReducer'
import RegionReducer from './RegionReducer'
import Promotion from './AddsManagerReducer'
import BandwidthReducer from './BandwidthReducer'
import CustomerServiceLogReducer from './CustomerServiceLogReducer'
import TaxClassificationReducer from './TaxClassificationReducer'
import APIAuthenticationReducer from './APIAuthenticationReducer'

const RootReducer = combineReducers({
	users: UserReducer,
	customers: CustomerReducer,
	permissions: PermissionReducer,
	notifications: NotificationReducer,
	roles: RoleReducer,
	settings: SettingReducer,
	mailManagement: MailManagementReducer,
	smsManagement: SMSManagementReducer,
	plansManagement: PlansManagementReducer,
	mailTemplate: MailTemplateReducer,
	logs: LogReducer,
	vendors: VendorReducer,
	carrier: CarriersReducer,
	questionnaire: QuestionnaireReducer,
	tooltips: TooltipsReducer,
	tooltipssteps: TooltipsReducerStepsReducer,
	tooltipsForms: TooltipsReducerFormReducer,
	order: OrderReducer,
	newNumber: NewNumberReducer,
	regionReducer: RegionReducer,
	promotion: Promotion,
	bandwidth: BandwidthReducer,
	serviceLogs: CustomerServiceLogReducer,
	taxClassification: TaxClassificationReducer,
	apiAuthentication: APIAuthenticationReducer

})

export default RootReducer

import {
    GET_LOGS,
    GET_USER_LOG
} from '../actions/LogsAction';

const initialState = {
    getLogs: [],
    getUserLogs: [],
}

const LogReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_LOGS:
            return {
                ...state,
                getLogs: [...action.payload]
            }
        case GET_USER_LOG:
            return {
                ...state,
                getUserLogs: [...action.payload]
            }
        default:
            return {
                ...state
            }
    }
}

export default LogReducer
import { ALL_PLANS, ALL_PLANS_ADDONS, ALL_PLANS_CATEGORIES, ALL_PLANS_FEATURES} from "../actions/PlansManagementAction";

const initialState = {
    categories: [],
    features: [],
    addOns: [],
    plans: [],
}

const PlansManagementReducer = function (state = initialState, action) {
    switch (action.type) {
        case ALL_PLANS_CATEGORIES: {
            return {
                ...state,
                categories: [...action.payload]
            }
        }
        case ALL_PLANS_FEATURES: {
            return {
                ...state,
                features: [...action.payload]
            }
        }
        case ALL_PLANS_ADDONS: {
            return {
                ...state,
                addOns: [...action.payload]
            }
        }
        case ALL_PLANS: {
            return {
                ...state,
                plans: [...action.payload]
            }
        }
        default: {
            return {...state}
        }
    }
}

export default PlansManagementReducer

import '../fake-db'
import React from 'react'
import { Provider } from 'react-redux'
import { Router, Switch, Route } from 'react-router-dom'
import AppContext from './contexts/AppContext'
import history from 'history.js'
import routes from './RootRoutes'
import { Store } from './redux/Store'
import { GlobalCss, MatxSuspense, MatxTheme, MatxLayout } from 'app/components'
import sessionRoutes from './views/auth/AuthRoutes'
import AuthGuard from './auth/AuthGuard'
import { AuthProvider } from 'app/contexts/AuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import { SnackbarProvider } from 'notistack'


const App = () => {
    return (

        <AppContext.Provider value={{ routes }}>
            <Provider store={Store}>
                <SettingsProvider>
                    <MatxTheme>
                        <GlobalCss />
                        <SnackbarProvider
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            TransitionProps={{
                                entering: { opacity: 1 },
                                entered: { opacity: 1 },
                                exiting: { opacity: 0 },
                                exited: { opacity: 0 },
                            }}
                          
                            maxSnack={3}
                            autoHideDuration={3000}
                        >
                            <Router history={history}>
                                <AuthProvider>
                                    <MatxSuspense>
                                        <Switch>
                                            {/* AUTHENTICATION PAGES (SIGNIN, SIGNUP ETC.) */}
                                            {sessionRoutes.map((item, i) => (
                                                <Route
                                                    key={i}
                                                    path={item.path}
                                                    component={item.component}
                                                />
                                            ))}
                                            {/* AUTH PROTECTED DASHBOARD PAGES */}
                                            <AuthGuard>
                                                <MatxLayout />{' '}
                                                {/* RETURNS <Layout1/> component */}
                                            </AuthGuard>
                                        </Switch>
                                    </MatxSuspense>
                                </AuthProvider>
                            </Router>
                        </SnackbarProvider>
                    </MatxTheme>
                </SettingsProvider>
            </Provider>
        </AppContext.Provider>

    )
}

export default App

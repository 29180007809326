import { ALL_SERVICE_LOGS, VIEW_SERVICE_LOG } from "../actions/CustomerServiceLogAction"

const initialState = {
	allLogs: {},
	viewLog: {}
}
const CustomerServiceLogReducer = function (state = initialState, action) {
	switch (action.type) {
		case ALL_SERVICE_LOGS: {
			return {
				...state,
				allLogs: { ...action.payload }
			}
		}

		case VIEW_SERVICE_LOG: {
			return {
				...state,
				viewLog: { ...action.payload }
			}
		}
		default: {
			return { ...state }
		}
	}
}

export default CustomerServiceLogReducer

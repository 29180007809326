import axios from "./../../../axios"

export const TAX_CLASSIFICATION_LIST = 'TAX_CLASSIFICATION_LIST'

export const getTaxClassificationList = () => (dispatch) => {
	axios.get('tax-classification').then((res) => {
		dispatch({
			type: TAX_CLASSIFICATION_LIST,
			payload: res.data.result,
		})
	})
}

export const getTaxClassification = (id) => (dispatch) => {
	return axios.get(`tax-classification/${id}`).then((res) => {
		return res.data
	})
}

export const createTaxClassification = (taxClassification) => (dispatch) => {
	return axios.post('tax-classification', taxClassification).then((res) => {

		return res.data
	})
}

export const updateTaxClassification = (taxClassification, id) => (dispatch) => {
	return axios.put(`tax-classification/${id}`, taxClassification).then((res) => {
		return res.data
	})
}

export const deleteTaxClassification = (id) => (dispatch) => {
	return axios.delete(`tax-classification/${id}`).then((res) => {
		return res.data
	})
}

export const getAssignClassificationPlan = (id) => async (dispatch) => {
	return axios.get(`tax-classification/assign-plan/${id}`).then((res) => {
		return res.data
	})
}

export const assignClassificationToPlan = (payload) => async (dispatch) => {
	return axios.post('tax-classification/assign-plan', payload).then((res) => {
		return res.data
	})
}

import axios from "./../../../axios"
export const GET_SETTING_LIST = 'GET_SETTING_LIST';
export const GET_SETTING_BY_APP = 'GET_SETTING_BY_APP';
export const UPDATE_SETTING = 'UPDATE_SETTING';





export const getSettings = () => (dispatch) => {
    return axios.get('general-settings/all').then((res) => {

        dispatch({
            type: GET_SETTING_LIST,
            payload: res.data.result
        });
    })
}
export const getSettingsByApplcation = (name) => (dispatch) => {
    return axios.get('general-settings/get-by-app/' + name).then((res) => {
        dispatch({
            type: GET_SETTING_BY_APP,
            payload: res.data.result
        });
        return res.data.result
    })
}

export const updateSettings = (payload) => (dispatch) => {

    return axios.post('general-settings/create', payload).then(resp => resp.data)
}

export const deleteSetting = (id) => (dispatch) => {

    return axios.delete('general-settings/delete/' + id).then(resp => {
        return resp.data
    })
}


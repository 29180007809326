import axios from "./../../../axios"

export const GET_LOGS = 'GET_LOGS'
export const GET_USER_LOG = 'GET_USER_LOG'


export const getLogs = () => (dispatch) => {
    axios.get('log/all').then((res) => {
        dispatch({
            type: GET_LOGS,
            payload: res.data.result,
        })
    })
}
export const getLogByUser = (id) => (dispatch) => {
    axios.get('log/user/' + id).then((res) => {
        dispatch({
            type: GET_USER_LOG,
            payload: res.data.result,
        })
    })
}
import {
 CREATE_ADDS,
 GET_ADDS
} from '../actions/AddsManagerAction'

const initialState = {
 AddsMarkups: []
}

const AddsManagerReducer = function (state = initialState, action) {
 switch (action.type) {
  case GET_ADDS: {
   return {
    ...state,
    AddsMarkups: [...action.payload]
}
}
  case CREATE_ADDS: {
      return [...action.payload]
  }
  default: {
   return {...state}
  }
}

}

export default AddsManagerReducer
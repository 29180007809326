import axios from "./../../../axios"

export const GET_QUESTIONNAIRE = 'GET_QUESTIONNAIRE'
export const CREATE_QUESTIONNAIRE = 'CREATE_QUESTIONNAIRE'
export const DELETE_QUESTIONNAIRE = 'DELETE_QUESTIONNAIRE'
export const DELETE_ALL_QUESTIONNAIRE = 'DELETE_ALL_QUESTIONNAIRE'



export const getQuestionnaire = () => (dispatch) => {
    return axios.get('questionnaire/all').then((res) => {
        dispatch({
            type: GET_QUESTIONNAIRE,
            payload: res.data.result,
        })
        return res.data;
    })
}
export const createQuestionnaire = (dispatch) => async () => {
    return axios.post('questionnaire/create', dispatch).then((res) => {
        return res.data;
    })
}

export const updateQuestionnaire = (dispatch) => async () => {
    return axios.post('questionnaire/update', dispatch).then((res) => {
        return res.data;
    })
}

export const getQuestionnaireById = (id) => (dispatch) => {
    return axios.get('questionnaire/show/' + id).then((res) => {
        return res.data;
    })
}


export const changeStatus = (data) => async () => {
    return axios.post('questionnaire/change-status/', data).then((res) => {
        return res.data;
    })
}

export const changePriority = (data) => async () => {
    return axios.post('questionnaire/change-priority/', data).then((res) => {
        return res.data;
    })
}

export const deleteQuestion = (id) => async () => {
    return axios.delete('questionnaire/delete/'+ id).then((res) => {
        return res.data;
    })
}
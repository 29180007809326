import { GET_COUNTRIES, SUB_DIVISION_BY_COUNTRY, NUMBER_LIST, CLEAR_LIST } from "../actions/NewNumberAction"


const initialState = {
    countryList: [],
    stateList: [],
    numberList: [],
}

const NewNumberReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_COUNTRIES:
            return {
                ...state,
                countryList: [...action.payload]
            }
        case SUB_DIVISION_BY_COUNTRY:
            return {
                ...state,
                stateList: [...action.payload]
            }
        case NUMBER_LIST:
            return {
                ...state,
                numberList: [...action.payload]
            }
        case CLEAR_LIST:
            return {
                ...state,
                numberList: []
            }
        default:
            return {
                ...state
            }
    }
}

export default NewNumberReducer
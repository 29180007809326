import { GET_ORDER_LIST, GET_STATUS_LIST } from "../actions/OrderActions"


const initialState = {
    orderList: [],
    orderStatusList: [],
}


const OrderReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_ORDER_LIST: {
            return {
                ...state,
                orderList: [...action.payload]
            }
        }
        case GET_STATUS_LIST: {
            return {
                ...state,
                orderStatusList: [...action.payload]
            }
        }

        default: {
            return { ...state }
        }
    }
}

export default OrderReducer
import {
    GET_CUSTOMER,
    CREATE_CUSTOMER,
    DELETE_ALL_CUSTOMER,
    DELETE_CUSTOMER,
} from '../actions/CustomerAction'

const initialState = []

const CustomerReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_CUSTOMER: {
            return [...action.payload]
        }
        case CREATE_CUSTOMER: {
            return [...action.payload]
        }
        case DELETE_CUSTOMER: {
            return state.filter((item) => item.id !== action.payload)
        }
        case DELETE_ALL_CUSTOMER: {
            return [...action.payload]
        }
        default: {
            return [...state]
        }
    }
}

export default CustomerReducer

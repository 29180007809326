import axios from "./../../../axios"

export const GET_PERMISSION = 'GET_PERMISSION'
export const CREATE_PERMISSION = 'CREATE_PERMISSION'
export const DELETE_PERMISSION = 'DELETE_PERMISSION'
export const DELETE_ALL_PERMISSION = 'DELETE_ALL_PERMISSION'

export const getPermissions = () => (dispatch) => {
    axios.post('permissions/all').then((res) => {
        dispatch({
            type: GET_PERMISSION,
            payload: res.data.result,
        })
    })
}
export const getPermission = (id) => (dispatch) => {
    return axios.post('permissions/get/' + id).then((res) => {
        return res.data
    })
}

export const getPermissionByRole = (id) => (dispatch) => {
    return axios.get('permissions/get-by-role/' + id).then((res) => {
        return res.data
    })
}

export const createPermission = (data) => () => {
    return axios.post('permissions/create', data).then((res) => {
        return res.data;
    })
}

export const updatePermission = (data) => () => {
    return axios.put('permissions/update', data).then((res) => {
        return res.data;
    })
}

export const deletePermission = (id) => (dispatch) => {
    return axios.delete('permissions/delete/' + id).then((res) => {
        return res.data;
    })
}


import { ALL_RECORDS, SITE_LIST } from "../actions/BandwidthAction"

const initialState = {
	'allRecord': {},
	'siteList': []
}

const BandwidthReducer = function (state = initialState, action) {
	switch (action.type) {
		case ALL_RECORDS: {
			return {
				...state,
				allRecord: { ...action.payload }
			}
		}
		case SITE_LIST: {
			return {
				...state,
				siteList: [...action.payload?.Sites?.Site]
			}
		}
		default: {
			return { ...state }
		}
	}
}

export default BandwidthReducer

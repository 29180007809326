import { ALL_API_KEYS } from "../actions/APIAuthenticationAction"

const initialState = []


const APIAuthenticationReducer = function (state = initialState, action) {
	switch (action.type) {
		case ALL_API_KEYS: {
			return [...action.payload]
		}
		default: {
			return [...state]
		}
	}
}

export default APIAuthenticationReducer

import Mock from '../mock'
import axios from 'axios.js'

const userDB = {
    users: [
        {
            id: '5a7b73f76bed15c94d1e46d4',
            index: 0,
            guid: 'c01da2d1-07f8-4acc-a1e3-72dda7310af8',
            isActive: false,
            order: 4,
            age: 30,
            name: 'Oren',
            gender: 'male',
            company: 'PMP',
            email: 'Oren@pmp.com',
            phone: '+1 (857) 535-2066',
            address: '163 Poplar Avenue, Cliffside, Virginia, 4592',
            bd: '2015-02-08T04:28:44 -06:00',
            imgUrl: '/assets/images/face-7.jpg',
            picture: '/assets/images/face-7.jpg',
        }
    ],
}

Mock.onGet('/api/user/all').reply(async (config) => {
    try {
        await new Promise((resolve) => setTimeout(resolve, 1000))
        
        const response = await axios.post('user/all');

        const user = response.data.result

        return [200, user]
        // return [200, userDB.users]
    } catch (err) {
        console.error(err)
        return [500, { message: 'Internal server error' }]
    }
})

Mock.onGet('/api/user').reply((config) => {
    const id = config.data
    const response = userDB.users.find((user) => user.id === id)
    return [200, response]
})

Mock.onPost('/api/user/delete').reply((config) => {
    let user = JSON.parse(config.data)
    let index = { i: 0 }
    userDB.users.forEach((element) => {
        if (element.id === user.id) {
            return [200, userDB.users.splice(index.i, 1)]
        }
        index.i++
    })
    return [200, userDB.users]
})

Mock.onPost('/api/user/update').reply((config) => {
    let user = JSON.parse(config.data)
    let index = { i: 0 }
    userDB.users.forEach((element) => {
        if (element.id === user.id) {
            userDB.users[index.i] = user
            return [200, userDB.users]
        }
        index.i++
    })
    return [200, userDB.users]
})

Mock.onPost('/api/user/add').reply((config) => {
    let user = JSON.parse(config.data)
    userDB.users.push(user)
    return [200, userDB.users]
})

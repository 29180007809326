import { GET_TOOLTIPSFORMS, SHOW_DATAFORM, SHOW_DATARELATIONSHIP, SHOW_DATARELATIONSHIPTOOLTIP } from "../actions/TooltipsFormAction"

const initialState = {

    getTooltipsFormAll: [],
    getTooltipsRelationData: [],
    getFormData:[],
    getFormDataOnTooltip:[]

}

const TooltipsReducerFormReducer = (state = initialState, action) => {

    switch (action.type) {

        case GET_TOOLTIPSFORMS: {
            return {
                ...state,
                getTooltipsFormAll: [...action.payload]
            }
        }
        case SHOW_DATARELATIONSHIP: {
       
            return {
                ...state,
                getTooltipsRelationData: [...action.payload]
            }
        }
        case SHOW_DATAFORM: {
       
            return {
                ...state,
                getFormData: [...action.payload]
            }
        }
        case SHOW_DATARELATIONSHIPTOOLTIP: {
       
            return {
                ...state,
                getFormDataOnTooltip: [...action.payload]
            }
        }

        
        default:
            return {
                ...state
            }

    }
}

export default TooltipsReducerFormReducer

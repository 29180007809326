import axios from "./../../../axios"

export const CREATE_ADDS = 'CREATE_ADDS'
export const GET_ADDS = 'GET_ADDS'

export const createAdds = (dispatch) => async () => {
 return axios.post('advertisement/create', dispatch).then((res) => {
  return res.data;
 })
}

export const getAdds = () => (dispatch) => {
 axios.get('advertisement/all').then(res => {
  dispatch({
   type: GET_ADDS,
   payload: res?.data?.result
  })
 })
}


export const findMarkupPromotion = (id) => async () => {
 return axios.get('advertisement/find/' + id).then((res) => {
     return res.data;
 })
}

export const updateMarkupPromotion = (markup) => async () => {
 return axios.post('advertisement/update', markup).then((res) => {
     return res.data;
 })
}

export const deleteMarkupPromotion = (id) => async () => {
 return axios.delete('advertisement/delete/' + id).then((res) => {
     return res.data;
 })
}
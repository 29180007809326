import axios from "../../../axios"

export const GET_TOOLTIPSFORMS = 'GET_TOOLTIPSFORMS'
export const CREATE_TOOLTIPSFORM = 'CREATE_TOOLTIPSFORM'
export const DELETE_TOOLTIPSFORM = 'DELETE_TOOLTIPSFORM'
export const DELETE_ALL_TOOLTIPSFORM = 'DELETE_ALL_TOOLTIPSFORM'
export const SHOW_DATARELATIONSHIP = 'SHOW_DATARELATIONSHIP'
export const SHOW_DATAFORM = 'SHOW_DATAFORM'
export const SHOW_DATARELATIONSHIPTOOLTIP = 'SHOW_DATARELATIONSHIPTOOLTIP'



export const getTooltipsForms = () => (dispatch) => {
    axios.get('tooltip-forms/all').then((res) => {
        dispatch({
            type: GET_TOOLTIPSFORMS,
            payload: res.data.result,
        })
    })
}




export const createTooltipsForms = (dispatch) => async () => {
    return axios.post('tooltip-forms/create', dispatch).then((res) => {
        return res.data;
    })
}


// export const changeStatus = (data, id) => async () => {
//     return axios.patch('carrier/change-status/' + id, data).then((res) => {
//         return res.data;
//     })
// }


export const updateTooltipsForms = (dispatch) => async () => {
    return axios.post('tooltip-forms/update', dispatch).then((res) => {
        return res.data;
    })
}

export const getTooltipsByIdForms = (id) => (dispatch) => {
    return axios.get('tooltip-forms/show/' + id).then((res) => {
        return res.data;
    })
}


export const getTooltipsByIdData = (id) => (dispatch) => {
    return axios.get('tooltip-forms/show-steps/' + id).then((res) => {
        dispatch({
            type: SHOW_DATARELATIONSHIP,
            payload: res.data.result.get_step_count,
        })
    })
}

export const getFormByIdData = (id) => (dispatch) => {
    return axios.get('tooltip-forms/show-steps/' + id).then((res) => {
        dispatch({
            type: SHOW_DATARELATIONSHIP,
            payload: res.data.result.get_form_count,
        })
    })
}
export const getFormByIdDataonTooltip = (id) => (dispatch) => {
    return axios.get('tooltip-forms/show-form2/' + id).then((res) => {
        dispatch({
            type: SHOW_DATARELATIONSHIPTOOLTIP,
            payload: res.data.result,
        })
    })
}
export const getFormData = (id) => (dispatch) => {
    return axios.get('tooltip-forms/show-form/' + id).then((res) => {
        dispatch({
            type: SHOW_DATAFORM,
            payload: res.data.result,
        })
    })
}

export const deleteTooltipsForms = (id) => async () => {
    return axios.delete('tooltip-forms/delete/' + id).then((res) => {
        return res.data;
    })
}

// export const massDelete = (dispatch) => async () => {
//     return axios.post('steps/mass-delete', dispatch).then((res) => {
//         return res.data;
//     })
// }

// export const massApproved = (data) => (dispatch) => {
//     return axios.post('steps/mass-approved', data).then((res) => {
//         // dispatch({
//         //     type: MARK_AS_READ,
//         //     payload: res.data.result,
//         // })
//         return res.data.result;
//     })
// }

// export const sortData = (data) => (dispatch) => {
//     return axios.get('steps/disapprove', data).then((res) => {
//         dispatch({
//             type: GET_TOOLTIPSFORM,
//             payload: res.data.result,
//         })
//     })
// }

